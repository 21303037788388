import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table";
import { 
  Pagination, 
  PaginationContent, 
  PaginationItem, 
  PaginationLink, 
  PaginationNext, 
  PaginationPrevious
} from "./ui/pagination";
import { Alert, AlertDescription } from "./ui/alert";
import { Eye } from 'lucide-react';
import { getResumes } from '../services/api';

const AllResumes = () => {
  const [resumes, setResumes] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchResumes();
  }, []);

  const fetchResumes = async () => {
    try {
      setLoading(true);
      const fetchedResumes = await getResumes();
      setResumes(fetchedResumes);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch resumes:', error);
      setError('Failed to load resumes. Please try again later.');
      setLoading(false);
    }
  };

  const filteredResumes = resumes.filter(resume => 
    resume.job_description.toLowerCase().includes(search.toLowerCase())
  );

  const paginatedResumes = filteredResumes.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const pageCount = Math.ceil(filteredResumes.length / rowsPerPage);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">All Resumes</h1>
      <div className="mb-4">
        <Input
          type="text"
          placeholder="Search by title..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="max-w-sm"
        />
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Title</TableHead>
            <TableHead>Created At</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {paginatedResumes.map((resume) => (
            <TableRow key={resume.id}>
              <TableCell className="font-medium">
                {resume.title}
              </TableCell>
              <TableCell>
                {new Date(resume.created_at).toLocaleString()}
              </TableCell>
              <TableCell>
                <Button
                  variant="outline"
                  size="sm"
                  asChild
                >
                  <Link to={`/tailored-resume/${resume.id}`}>
                    <Eye className="mr-2 h-4 w-4" /> View
                  </Link>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination className="mt-4">
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious 
              onClick={() => setPage(prev => Math.max(prev - 1, 1))}
              disabled={page === 1}
            />
          </PaginationItem>
          {[...Array(pageCount)].map((_, index) => (
            <PaginationItem key={index}>
              <PaginationLink 
                onClick={() => setPage(index + 1)}
                isActive={page === index + 1}
              >
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem>
            <PaginationNext 
              onClick={() => setPage(prev => Math.min(prev + 1, pageCount))}
              disabled={page === pageCount}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </div>
  );
};

export default AllResumes;
