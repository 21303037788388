import api from './api';

export const isTokenValid = async (token) => {
  try {
    // Make a request to a protected endpoint to check if the token is still valid
    await api.get('/protected');
    return true;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return false;
    }
    // If there's a network error or other issues, assume the token is invalid
    return false;
  }
};

const handleApiError = (error, customErrorMessage) => {
    console.error(customErrorMessage, error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }
    throw error;
  };

export const registerUser = async (username, email, password) => {
    try {
      const response = await api.post('/register', { username, email, password });
      return response.data;
    } catch (error) {
      handleApiError(error, 'Error in registerUser:');
    }
  };
  
  export const loginUser = async (username, password) => {
    try {
      const response = await api.post('/login', { username, password });
      localStorage.setItem('token', response.data.access_token);
      // If your backend sends a refresh token, store it as well
      // localStorage.setItem('refreshToken', response.data.refresh_token);
      return response.data;
    } catch (error) {
      handleApiError(error, 'Error in loginUser:');
    }
  };

export const logout = () => {
  localStorage.removeItem('token');
  // If you're using refresh tokens, remove that as well
  // localStorage.removeItem('refreshToken');
  
  // You might want to make an API call to invalidate the token on the server
  // api.post('/logout');
  
  // Redirect to login page
  window.location.href = '/login';
};

  