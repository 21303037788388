import React from "react";
import { Link } from "react-router-dom";
import { ChevronRight, Zap, TrendingUp, Award } from "lucide-react";

const Feature = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
    <Icon className="w-12 h-12 text-blue-500 mb-4" />
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 text-center">{description}</p>
  </div>
);

const Home = () => {
  const isAuthenticated = !!localStorage.getItem("token");

  return (
    <div className="min-h-screen bg-gray-100">
      <main className="container mx-auto px-4 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Tailor Your Resume with CV Minion
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Stand out from the crowd and increase your chances of landing that
            dream job.
          </p>
          <Link
            to={isAuthenticated ? "/upload-resume" : "/login"}
            className="inline-flex items-center px-6 py-3 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
          >
            {isAuthenticated ? "Get Started" : "Login to Get Started"}
            <ChevronRight className="ml-2 w-5 h-5" />
          </Link>
        </div>

        {/* Features Section */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          <Feature
            icon={Zap}
            title="AI-Powered Tailoring"
            description="Our advanced AI analyzes your resume and the job description to create a perfectly tailored resume."
          />
          <Feature
            icon={TrendingUp}
            title="Increase Your Chances"
            description="Stand out from other applicants with a resume optimized for each specific job application."
          />
          <Feature
            icon={Award}
            title="Easy to Use"
            description="Simply upload your resume and paste the job description. We'll do the rest!"
          />
        </div>

        {/* Call to Action */}
        <div className="bg-blue-600 text-white rounded-lg p-8 text-center">
          <h2 className="text-3xl font-bold mb-4">
            Ready to land your dream job?
          </h2>
          <p className="text-xl mb-6">
            Start tailoring your resume now and increase your chances of getting
            that interview!
          </p>
          <Link
            to={isAuthenticated ? "/upload-resume" : "/register"}
            className="inline-flex items-center px-6 py-3 text-blue-600 bg-white rounded-md hover:bg-gray-100 transition-colors"
          >
            {isAuthenticated ? "Tailor Your Resume" : "Sign Up Now"}
            <ChevronRight className="ml-2 w-5 h-5" />
          </Link>
        </div>
      </main>
    </div>
  );
};

export default Home;
