import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";
import { Alert, AlertDescription } from "./ui/alert";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Loader2 } from "lucide-react";
import { getResumeById, retailorResume } from "../services/api";

const RetailorResume = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [jobDescription, setJobDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOriginalResume = async () => {
      try {
        const resume = await getResumeById(id);
        setJobDescription(resume.job_description);
      } catch (error) {
        console.error("Failed to fetch original resume:", error);
        setError("Failed to load the original resume. Please try again later.");
      }
    };

    fetchOriginalResume();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const result = await retailorResume(id, jobDescription);
      navigate(`/tailored-resume/${result.id}`);
    } catch (error) {
      console.error("Failed to retailor resume:", error);
      setError("Failed to retailor resume. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto max-w-2xl mt-10">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold">Retailor Resume</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <Textarea
              placeholder="Enter job description"
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              rows={10}
              required
            />
            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            <Button type="submit" disabled={loading} className="w-full">
              {loading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : null}
              Retailor Resume
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default RetailorResume;
