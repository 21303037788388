import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Alert, AlertDescription } from "./ui/alert";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { Loader2, Plus, Eye, RefreshCw, Trash, FileText } from "lucide-react";
import { getResumes, deleteResume, getUserResumes } from "../services/api";
import ResumeUpload from "./ResumeUpload";

const Dashboard = () => {
  const [tailoredResumes, setTailoredResumes] = useState([]);
  const [uploadedResumes, setUploadedResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [resumeToDelete, setResumeToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchResumes();
    fetchUploadedResumes();
  }, []);

  const fetchResumes = async () => {
    try {
      setLoading(true);
      const fetchedResumes = await getResumes();
      setTailoredResumes(fetchedResumes);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch tailored resumes:", error);
      setError("Failed to load tailored resumes. Please try again later.");
      setLoading(false);
    }
  };

  const fetchUploadedResumes = async () => {
    try {
      const fetchedUploadedResumes = await getUserResumes();
      setUploadedResumes(fetchedUploadedResumes);
    } catch (error) {
      console.error("Failed to fetch uploaded resumes:", error);
    }
  };

  const handleDeleteClick = (resume, isTailored) => {
    setResumeToDelete({ ...resume, isTailored });
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteResume(resumeToDelete.id);
      if (resumeToDelete.isTailored) {
        setTailoredResumes(
          tailoredResumes.filter((resume) => resume.id !== resumeToDelete.id)
        );
      } else {
        setUploadedResumes(
          uploadedResumes.filter((resume) => resume.id !== resumeToDelete.id)
        );
      }
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Failed to delete resume:", error);
      setError("Failed to delete resume. Please try again later.");
    }
  };

  const handleRetailor = (resumeId) => {
    navigate(`/retailor-resume/${resumeId}`);
  };

  const handleViewResume = (resumeId, isTailored) => {
    if (isTailored) {
      navigate(`/tailored-resume/${resumeId}`);
    } else {
      console.log(`View uploaded resume with id: ${resumeId}`);
    }
  };

  const handleTailorResume = (resumeId) => {
    navigate(`/tailor-resume/${resumeId}`);
  };

  return (
    <div className="container mx-auto p-4">
      <Tabs defaultValue="dashboard">
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="dashboard">Dashboard</TabsTrigger>
          <TabsTrigger value="upload">Upload Resume</TabsTrigger>
          <TabsTrigger value="all">All Resumes</TabsTrigger>
        </TabsList>

        <TabsContent value="dashboard">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Card>
              <CardHeader>
                <CardTitle>Summary</CardTitle>
              </CardHeader>
              <CardContent>
                <p>Tailored Resumes: {tailoredResumes.length}</p>
                <p>Uploaded Resumes: {uploadedResumes.length}</p>
                <p>
                  Last Updated:{" "}
                  {tailoredResumes[0]
                    ? new Date(
                        tailoredResumes[0].created_at
                      ).toLocaleDateString()
                    : "N/A"}
                </p>
                <Button
                  className="mt-4"
                  onClick={() => navigate("/upload-resume")}
                >
                  <Plus className="mr-2 h-4 w-4" /> Upload New Resume
                </Button>
              </CardContent>
            </Card>

            <Card className="md:col-span-2">
              <CardHeader>
                <CardTitle>Recent Tailored Resumes</CardTitle>
              </CardHeader>
              <CardContent>
                {loading ? (
                  <div className="flex justify-center">
                    <Loader2 className="h-6 w-6 animate-spin" />
                  </div>
                ) : error ? (
                  <Alert variant="destructive">
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                ) : (
                  <ul>
                    {tailoredResumes.slice(0, 5).map((resume) => (
                      <li
                        key={resume.id}
                        className="flex justify-between items-center mb-2 p-2 bg-gray-100 rounded"
                      >
                        <span>
                          {resume.title ||
                            resume.job_description.slice(0, 30) + ".."}{" "}
                        </span>
                        <span className="text-sm text-gray-500">
                          {new Date(resume.created_at).toLocaleString()}
                        </span>
                        <div>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleViewResume(resume.id, true)}
                          >
                            <Eye className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleRetailor(resume.id)}
                          >
                            <RefreshCw className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleDeleteClick(resume, true)}
                          >
                            <Trash className="h-4 w-4" />
                          </Button>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                {tailoredResumes.length > 5 && (
                  <Button
                    variant="link"
                    onClick={() => document.getElementById("all-tab").click()}
                  >
                    View All Resumes
                  </Button>
                )}

                <Button
                  className="mt-2"
                  onClick={() => navigate("/tailor-resume")}
                >
                  <FileText className="mr-2 h-4 w-4" /> Tailor Resume
                </Button>
              </CardContent>
            </Card>
          </div>
        </TabsContent>

        <TabsContent value="upload">
          <ResumeUpload onUploadSuccess={fetchUploadedResumes} />
        </TabsContent>

        <TabsContent value="all">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Card>
              <CardHeader>
                <CardTitle>Uploaded Resumes</CardTitle>
              </CardHeader>
              <CardContent>
                <ul>
                  {uploadedResumes.map((resume) => (
                    <li
                      key={resume.id}
                      className="flex justify-between items-center mb-2 p-2 bg-gray-100 rounded"
                    >
                      <span>{resume.filename}</span>
                      <span className="text-sm text-gray-500">
                        {new Date(resume.uploaded_at).toLocaleString()}
                      </span>
                      <div>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleViewResume(resume.id, false)}
                        >
                          <Eye className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleTailorResume(resume.id)}
                        >
                          <FileText className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleDeleteClick(resume, false)}
                        >
                          <Trash className="h-4 w-4" />
                        </Button>
                      </div>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Tailored Resumes</CardTitle>
              </CardHeader>
              <CardContent>
                <ul>
                  {tailoredResumes.map((resume) => (
                    <li
                      key={resume.id}
                      className="flex justify-between items-center mb-2 p-2 bg-gray-100 rounded"
                    >
                      <span>
                        {resume.title ||
                          resume.job_description.slice(0, 20) + ".."}{" "}
                      </span>
                      <span className="text-sm text-gray-500">
                        {new Date(resume.created_at).toLocaleString()}
                      </span>
                      <div>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleViewResume(resume.id, true)}
                        >
                          <Eye className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleRetailor(resume.id)}
                        >
                          <RefreshCw className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleDeleteClick(resume, true)}
                        >
                          <Trash className="h-4 w-4" />
                        </Button>
                      </div>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </div>
        </TabsContent>
      </Tabs>

      <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Resume?</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this{" "}
              {resumeToDelete?.isTailored ? "tailored" : "uploaded"} resume?
              This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setDeleteDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleDeleteConfirm}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Dashboard;
