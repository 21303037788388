import React, { useEffect, useState }  from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { isTokenValid } from './services/auth';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import ResumeUpload from './components/ResumeUpload';
import TailorResume from './components/TailorResume';
import TailoredResume from './components/TailoredResume';
import AllResumes from './components/AllResumes';
import RetailorResume from './components/RetailorResume';
import EditableTailoredResume from './components/EditableTailoredResume';
import UserProfile from './components/UserProfile';

const PrivateRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const valid = await isTokenValid(token);
        setIsAuthenticated(valid);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/upload-resume" element={<PrivateRoute><ResumeUpload /></PrivateRoute>} />
            <Route path="/tailor-resume" element={<PrivateRoute><TailorResume /></PrivateRoute>} />
            <Route path="/tailor-resume/:id" element={<PrivateRoute><TailorResume /></PrivateRoute>} />
            <Route path="/tailored-resume/:id" element={<PrivateRoute><TailoredResume /></PrivateRoute>} />
            <Route path="/all-resumes" element={<PrivateRoute><AllResumes /></PrivateRoute>} />
            <Route path="/retailor-resume/:id" element={<PrivateRoute><RetailorResume /></PrivateRoute>} />
            <Route path="/edit-tailored-resume/:id" element={<PrivateRoute><EditableTailoredResume /></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
