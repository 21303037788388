import axios from 'axios';
import { logout } from './auth';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8080';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalRequest = error.config;
  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      // Implement refreshToken logic here if your backend supports it
      // const refreshToken = localStorage.getItem('refreshToken');
      // const response = await api.post('/refresh-token', { refreshToken });
      // const { token } = response.data;
      // localStorage.setItem('token', token);
      // originalRequest.headers['Authorization'] = 'Bearer ' + token;
      // return api(originalRequest);
      logout();
      window.location.href = '/login';
      return Promise.reject(error);
    } catch (refreshError) {
      // If refresh token fails, logout the user
      logout();
      window.location.href = '/login';
      return Promise.reject(refreshError);
    }
  }
  return Promise.reject(error);
});


export const uploadResume = async (file) => {
  const formData = new FormData();
  formData.append('resume', file);

  try {
    const response = await api.post('/upload-resume', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error in uploadResume:', error);
    throw error;
  }
};

export const getResumeById = async (id) => {
  try {
    const response = await api.get(`/get-resume/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error in getResumeById:', error);
    throw error;
  }
};

export const getUserResumes = async () => {
  try {
    const response = await api.get('/get-user-resumes');
    return response.data;
  } catch (error) {
    console.error('Error in getUserResumes:', error);
    throw error;
  }
};

const CACHE_KEY = 'cachedResumes';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const getResumes = async (forceRefresh = false, retries = 3, backoff = 300) => {
  if (!forceRefresh) {
    const cachedData = localStorage.getItem(CACHE_KEY);
    const cachedTime = localStorage.getItem(CACHE_KEY + '_time');

    if (cachedData && cachedTime) {
      const now = new Date().getTime();
      if (now - parseInt(cachedTime) < CACHE_DURATION) {
        return JSON.parse(cachedData);
      }
    }
  }

  try {
    const response = await api.get('/get-resumes');
    localStorage.setItem(CACHE_KEY, JSON.stringify(response.data));
    localStorage.setItem(CACHE_KEY + '_time', new Date().getTime().toString());
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 429 && retries > 0) {
      console.log(`Rate limited. Retrying in ${backoff}ms...`);
      await wait(backoff);
      return getResumes(forceRefresh, retries - 1, backoff * 2);
    }
    console.error('Error in getResumes:', error);
    throw error;
  }
};

export const deleteResume = async (resumeId) => {
  try {
    const response = await api.delete(`/delete-resume/${resumeId}`);
    return response.data;
  } catch (error) {
    console.error('Error in deleteResume:', error);
    throw error;
  }
};

export const tailorResume = async (formData) => {
  try {
    const response = await api.post('/tailor-resume', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error in tailorResume:', error);
    throw error;
  }
};
export const retailorResume = async (resumeId, jobDescription) => {
  try {
    const response = await api.post(`/retailor-resume/${resumeId}`, {
      job_description: jobDescription,
    });
    return response.data;
  } catch (error) {
    console.error('Error in retailorResume:', error);
    throw error;
  }
};

export const updateResumeTitle = async (resumeId, title) => {
  try {
    const response = await api.put(`/update-resume-title/${resumeId}`, { title });
    return response.data;
  } catch (error) {
    console.error('Error in updateResumeTitle:', error);
    throw error;
  }
};

export const updateTailoredResume = async (resumeId, updatedResume) => {
  try {
    const response = await api.put(`/update-tailored-resume/${resumeId}`, updatedResume);
    return response.data;
  } catch (error) {
    console.error('Error in updateTailoredResume:', error);
    throw error;
  }
};

export const getUserProfile = async () => {
  try {
    const response = await api.get('/user-profile');
    return response.data;
  } catch (error) {
    console.error('Error in getUserProfile:', error);
    throw error;
  }
};

export const updateUserProfile = async (profileData) => {
  try {
    const response = await api.put('/user-profile', profileData);
    return response.data;
  } catch (error) {
    console.error('Error in updateUserProfile:', error);
    throw error;
  }
};

export default api;
