import React, { useState, useEffect } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Alert, AlertDescription } from "./ui/alert";
import { Progress } from "./ui/progress";
import { uploadResume, getUserResumes } from "../services/api";
import { FileUp, File } from "lucide-react";

function ResumeUpload() {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [resumes, setResumes] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    fetchResumes();
  }, []);

  const fetchResumes = async () => {
    try {
      const fetchedResumes = await getUserResumes();
      setResumes(fetchedResumes);
    } catch (error) {
      console.error("Failed to fetch resumes:", error);
      setError("Failed to load resumes. Please try again later.");
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.size <= 5 * 1024 * 1024) {
      // 5MB limit
      setFile(selectedFile);
      setError("");
    } else {
      setFile(null);
      setError("Please select a file smaller than 5MB.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    setSuccess(false);
    setUploadProgress(0);

    try {
      await uploadResume(file, (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      });
      setSuccess(true);
      setFile(null);
      fetchResumes();
    } catch (error) {
      console.error("Error uploading resume:", error);
      setError("Failed to upload resume. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Upload Your Resume</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex items-center space-x-2">
          <Input
            type="file"
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx"
            id="resume-upload"
            className="hidden"
          />
          <label
            htmlFor="resume-upload"
            className="cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <FileUp className="h-5 w-5 mr-2" />
            Select Resume
          </label>
          {file && <span className="text-sm text-gray-500">{file.name}</span>}
        </div>
        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        {success && (
          <Alert>
            <AlertDescription>Resume uploaded successfully!</AlertDescription>
          </Alert>
        )}
        {loading && <Progress value={uploadProgress} className="w-full" />}
        <Button type="submit" disabled={!file || loading}>
          {loading ? "Uploading..." : "Upload Resume"}
        </Button>
      </form>

      <div className="mt-8">
        <h3 className="text-xl font-semibold mb-4">Your Uploaded Resumes</h3>
        {resumes.length > 0 ? (
          <ul className="space-y-2">
            {resumes.map((resume) => (
              <li
                key={resume.id}
                className="flex items-center space-x-2 text-sm text-gray-600"
              >
                <File className="h-4 w-4" />
                <span>{resume.filename}</span>
                <span className="text-gray-400">
                  {new Date(resume.uploaded_at).toLocaleString()}
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No resumes uploaded yet.</p>
        )}
      </div>
    </div>
  );
}

export default ResumeUpload;
