import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";
import { Alert, AlertDescription } from "./ui/alert";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Loader2, Upload } from "lucide-react";
import { tailorResume, getUserResumes } from "../services/api";

function TailorResume() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [resumes, setResumes] = useState([]);
  const [selectedResumeId, setSelectedResumeId] = useState(id || "");
  const [jobDescription, setJobDescription] = useState("");
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    fetchUserResumes();
  }, []);

  const fetchUserResumes = async () => {
    try {
      setLoading(true);
      const fetchedResumes = await getUserResumes();
      setResumes(fetchedResumes);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch resumes:", error);
      setError("Failed to load resumes. Please try again later.");
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedResumeId(""); // Clear selected resume when a file is uploaded
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    setSuccess(false);

    try {
      const formData = new FormData();

      if (selectedResumeId) {
        formData.append("resume_id", selectedResumeId);
      } else if (file) {
        formData.append("resume", file);
      } else {
        throw new Error("Please select a resume or upload a new one.");
      }

      formData.append("job_description", jobDescription);
      formData.append('title', title);

      console.log("Submitting form data:", {
        resume_id: selectedResumeId,
        file: file ? file.name : null,
        job_description: jobDescription,
      });

      const response = await tailorResume(formData);
      setSuccess(true);
      setTimeout(() => navigate(`/tailored-resume/${response.id}`), 1500);
    } catch (error) {
      console.error("Error tailoring resume:", error);
      setError(error.message || "Failed to tailor resume. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto max-w-2xl mt-10">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold">
            Tailor Your Resume
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="resume-select">Select an existing resume</Label>
              <Input
                id="resume-title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter a title for this tailored resume"
                required
              />
              <Select
                onValueChange={(value) => {
                  console.log("Selected resume ID:", value);
                  setSelectedResumeId(value);
                  setFile(null); // Clear file when a resume is selected
                }}
                value={selectedResumeId}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select Resume" />
                </SelectTrigger>
                <SelectContent>
                  {resumes.map((resume) => (
                    <SelectItem key={resume.id} value={resume.id.toString()}>
                      {resume.filename}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="resume-upload">Or upload a new resume</Label>
              <Input
                id="resume-upload"
                type="file"
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="job-description">Job Description</Label>
              <Textarea
                id="job-description"
                placeholder="Enter job description"
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
                rows={4}
              />
            </div>

            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            {success && (
              <Alert>
                <AlertDescription>
                  Resume tailored successfully! Redirecting...
                </AlertDescription>
              </Alert>
            )}
            <Button
              type="submit"
              disabled={
                (!selectedResumeId && !file) || !jobDescription || loading
              }
              className="w-full"
            >
              {loading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <Upload className="mr-2 h-4 w-4" />
              )}
              Tailor Resume
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default TailorResume;
