import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { Alert, AlertDescription } from "./ui/alert";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Briefcase, GraduationCap, Award, Save } from "lucide-react";
import { getResumeById, updateTailoredResume } from "../services/api";
import { debounce } from "lodash";

function EditableTailoredResume() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [resume, setResume] = useState(null);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState("");

  useEffect(() => {
    const fetchResume = async () => {
      try {
        setLoading(true);
        const response = await getResumeById(id);
        setResume(response.tailored_resume);
        setTitle(response.title);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching resume:", err);
        setError("Failed to load the resume. Please try again later.");
        setLoading(false);
      }
    };

    fetchResume();
  }, [id]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    debouncedSave({ ...resume, title: e.target.value });
  };

  const saveResume = useCallback(
    async (resumeData) => {
      try {
        setSaveStatus("Saving...");
        await updateTailoredResume(id, resumeData);
        setSaveStatus("Changes saved");
        setTimeout(() => setSaveStatus(""), 2000);
      } catch (err) {
        console.error("Error saving resume:", err);
        setSaveStatus("Error saving changes");
      }
    },
    [id]
  );

  // Debounce the save function to avoid too frequent API calls
  // eslint-disable-next-line
  const debouncedSave = useCallback(debounce(saveResume, 1000), [saveResume]);

  const handleInputChange = (e, section, index, field) => {
    const { value } = e.target;
    setResume((prevResume) => {
      let updatedResume;
      if (Array.isArray(prevResume[section])) {
        const newSection = [...prevResume[section]];
        if (field === "responsibilities") {
          newSection[index] = {
            ...newSection[index],
            [field]: value.split("\n"),
          };
        } else {
          newSection[index] = { ...newSection[index], [field]: value };
        }
        updatedResume = { ...prevResume, [section]: newSection };
      } else {
        updatedResume = { ...prevResume, [section]: value };
      }
      debouncedSave(updatedResume);
      return updatedResume;
    });
  };

  const handleFinishEditing = async () => {
    try {
      await saveResume(resume);
      navigate(`/tailored-resume/${id}`);
    } catch (err) {
      console.error("Error saving resume:", err);
      setError("Failed to save the resume. Please try again.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  if (!resume) {
    return (
      <Alert>
        <AlertDescription>Resume not found.</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="max-width-4xl mx-auto p-4">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Edit Tailored Resume</CardTitle>
        <div className="flex items-center">
          {saveStatus && (
            <span
              className={`text-sm mr-2 ${
                saveStatus === "Changes saved"
                  ? "text-green-500"
                  : saveStatus === "Error saving changes"
                  ? "text-red-500"
                  : "text-gray-500"
              }`}
            >
              {saveStatus}
            </span>
          )}
          <Save
            className={`h-5 w-5 ${
              saveStatus === "Changes saved"
                ? "text-green-500"
                : saveStatus === "Error saving changes"
                ? "text-red-500"
                : "text-gray-500"
            }`}
          />
        </div>
      </CardHeader>
      <CardContent>
        <Input
          value={title}
          onChange={handleTitleChange}
          className="mb-2"
          placeholder="Resume Title"
        />
        <Input
          value={resume.name}
          onChange={(e) => handleInputChange(e, "name")}
          className="mb-2"
          placeholder="Name"
        />
        <Input
          value={resume.email}
          onChange={(e) => handleInputChange(e, "email")}
          className="mb-2"
          placeholder="Email"
        />
        <Input
          value={resume.phone_number}
          onChange={(e) => handleInputChange(e, "phone_number")}
          className="mb-2"
          placeholder="Phone Number"
        />
        <Textarea
          value={resume.summary}
          onChange={(e) => handleInputChange(e, "summary")}
          className="mb-4"
          placeholder="Summary"
        />
        <Card className="mb-4">
          <CardHeader>
            <CardTitle className="flex items-center">
              <Briefcase className="mr-2 h-5 w-5" /> Experience
            </CardTitle>
          </CardHeader>
          <CardContent>
            {resume.experience.map((exp, index) => (
              <div key={index} className="mb-4">
                <Input
                  value={exp.title}
                  onChange={(e) =>
                    handleInputChange(e, "experience", index, "title")
                  }
                  className="mb-2"
                  placeholder="Job Title"
                />
                <Input
                  value={exp.company}
                  onChange={(e) =>
                    handleInputChange(e, "experience", index, "company")
                  }
                  className="mb-2"
                  placeholder="Company"
                />
                <Textarea
                  value={
                    Array.isArray(exp.responsibilities)
                      ? exp.responsibilities.join("\n")
                      : exp.responsibilities
                  }
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "experience",
                      index,
                      "responsibilities"
                    )
                  }
                  className="mb-2"
                  placeholder="Responsibilities (one per line)"
                />
              </div>
            ))}
          </CardContent>
        </Card>

        <Card className="mb-4">
          <CardHeader>
            <CardTitle className="flex items-center">
              <Award className="mr-2 h-5 w-5" /> Skills
            </CardTitle>
          </CardHeader>
          <CardContent>
            <Textarea
              value={
                Array.isArray(resume.skills)
                  ? resume.skills.join("\n")
                  : resume.skills
              }
              onChange={(e) =>
                setResume({ ...resume, skills: e.target.value.split("\n") })
              }
              className="mb-2"
              placeholder="Skills (one per line)"
            />
          </CardContent>
        </Card>

        <Card className="mb-4">
          <CardHeader>
            <CardTitle className="flex items-center">
              <GraduationCap className="mr-2 h-5 w-5" /> Education
            </CardTitle>
          </CardHeader>
          <CardContent>
            {resume.education.map((edu, index) => (
              <div key={index} className="mb-4">
                <Input
                  value={edu.degree}
                  onChange={(e) =>
                    handleInputChange(e, "education", index, "degree")
                  }
                  className="mb-2"
                  placeholder="Degree"
                />
                <Input
                  value={edu.institution}
                  onChange={(e) =>
                    handleInputChange(e, "education", index, "institution")
                  }
                  className="mb-2"
                  placeholder="Institution"
                />
              </div>
            ))}
          </CardContent>
        </Card>

        <Button onClick={handleFinishEditing} className="mt-4">
          Finish Editing
        </Button>
      </CardContent>
    </div>
  );
}

export default EditableTailoredResume;
