import React, { useState } from "react";
import { Button } from "./ui/button";
import { Download } from "lucide-react";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "./ui/select";
import api from "../services/api";

const templates = [
  { value: "modern", label: "Modern" },
  { value: "classic", label: "Classic" },
  { value: "creative", label: "Creative" },
];

const ResumeDownloadButton = ({ resumeId }) => {
  const [template, setTemplate] = useState("modern");

  const handleDownload = async () => {
    try {
      const response = await api.get(
        `/download-resume/${resumeId}?template=${template}`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `tailored_resume_${resumeId}_${template}.pdf`
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading resume:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <Select value={template} onValueChange={setTemplate}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Choose template" />
        </SelectTrigger>
        <SelectContent>
          {templates.map((t) => (
            <SelectItem key={t.value} value={t.value}>
              {t.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Button onClick={handleDownload}>
        <Download className="mr-2 h-4 w-4" /> Download Resume
      </Button>
    </div>
  );
};

export default ResumeDownloadButton;
