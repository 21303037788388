import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Button } from "./ui/button";
import { Alert, AlertDescription } from "./ui/alert";
import { Progress } from "./ui/progress";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from "./ui/card";
import { ArrowLeft, Briefcase, GraduationCap, Award } from "lucide-react";
import { getResumeById } from "../services/api";
import ResumeDownloadButton from "./ResumeDownloadButton";

function TailoredResume() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tailoredResume, setTailoredResume] = useState(null);
  const [confidenceScore, setConfidenceScore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResume = async () => {
      try {
        setLoading(true);
        const response = await getResumeById(id);
        setTailoredResume(response.tailored_resume);
        setConfidenceScore(response.confidence_score);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching resume:", err);
        setError("Failed to load the resume. Please try again later.");
        setLoading(false);
      }
    };

    fetchResume();
  }, [id]);

  // const handleDownload = () => {
  //   if (!tailoredResume) return;
  //   const element = document.createElement("a");
  //   const file = new Blob([JSON.stringify(tailoredResume, null, 2)], {type: 'text/plain'});
  //   element.href = URL.createObjectURL(file);
  //   element.download = `tailored_resume_${id}.json`;
  //   document.body.appendChild(element);
  //   element.click();
  // };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Progress className="w-1/2" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-2xl mx-auto p-4">
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
        <Button variant="outline" className="mt-4" onClick={() => navigate(-1)}>
          <ArrowLeft className="mr-2 h-4 w-4" /> Go Back
        </Button>
      </div>
    );
  }

  if (!tailoredResume) {
    return (
      <div className="max-w-2xl mx-auto p-4">
        <Alert>
          <AlertDescription>
            Resume not found or still loading.
          </AlertDescription>
        </Alert>
        <Button variant="outline" className="mt-4" onClick={() => navigate(-1)}>
          <ArrowLeft className="mr-2 h-4 w-4" /> Go Back
        </Button>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Tailored Resume</h1>
        <div className="space-x-2">
          <Button variant="outline" onClick={() => navigate(-1)}>
            <ArrowLeft className="mr-2 h-4 w-4" /> Go Back
          </Button>
        </div>
        <Button asChild>
          <Link to={`/edit-tailored-resume/${id}`}>Edit</Link>
        </Button>
        {/* <div className="space-x-2"> */}
        <ResumeDownloadButton className="mmr-2 h-4 w-4" resumeId={id} />
        {/* </div> */}
      </div>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Confidence Score: {confidenceScore * 100}%</CardTitle>
        </CardHeader>
        <CardContent>
          <Progress value={confidenceScore * 100} />
          <CardDescription></CardDescription>
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle>{tailoredResume.name || "N/A"}</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            {tailoredResume.email || "N/A"} |{" "}
            {tailoredResume.phone_number || "N/A"}
          </p>
          <p>{tailoredResume.address || "N/A"}</p>
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Summary</CardTitle>
        </CardHeader>
        <CardContent>
          <p>{tailoredResume.summary}</p>
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="flex items-center">
            <Briefcase className="mr-2 h-5 w-5" /> Experience
          </CardTitle>
        </CardHeader>
        <CardContent>
          {tailoredResume.experience &&
            tailoredResume.experience.map((exp, index) => (
              <div key={index} className="mb-4">
                <h3 className="font-semibold">
                  {exp.title} at {exp.company}
                </h3>
                <p className="text-sm text-gray-600">{exp.location}</p>
                <p className="text-sm text-gray-600">
                  {exp.start_date} - {exp.end_date}
                </p>
                <ul className="list-disc list-inside mt-2">
                  {exp.responsibilities &&
                    exp.responsibilities.map((resp, i) => (
                      <li key={i}>{resp}</li>
                    ))}
                </ul>
              </div>
            ))}
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="flex items-center">
            <Award className="mr-2 h-5 w-5" /> Skills
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="list-disc list-inside">
            {tailoredResume.skills &&
              tailoredResume.skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="flex items-center">
            <GraduationCap className="mr-2 h-5 w-5" /> Education
          </CardTitle>
        </CardHeader>
        <CardContent>
          {tailoredResume.education &&
            tailoredResume.education.map((edu, index) => (
              <div key={index} className="mb-2">
                <h3 className="font-semibold">
                  {edu.degree} - {edu.institution}
                </h3>
                <p className="text-sm text-gray-600">
                  {edu.start_date} - {edu.end_date}
                </p>
              </div>
            ))}
        </CardContent>
      </Card>
    </div>
  );
}

export default TailoredResume;
